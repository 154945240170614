<template>

    <section class="sectionNews">

      <v-container>
        <v-row >
          <v-col  cols="12" class="py-2 px-2">
        
          </v-col>
              <v-col md="8" cols="12" class="pb-0 pt-4">
                  <v-row :key="neww.date" v-for="neww in news">
              <v-col md="6" cols="12" >
                       <img class="img-fluid" :src="neww.src" alt="img" />

            </v-col>
             <v-col md="6" cols="12" >
              <span class="dateTxt">{{ $t('new.date')}}</span>
              <h2 v-bind:class="$i18n.locale === 'ar' ? ' newsHeadAr' : 'newsHead'">{{ $t('new.head')}}</h2>
               <p>{{ $t('new.caption')}}</p>
               <router-link :to="{name: 'news-item', params:{id:neww.id ,lang: $i18n.locale}}"  v-bind:class="$i18n.locale === 'ar' ?'ar-font moreAr' : ' more'"> {{ $t('Read More')}}
                             <img class="arrow-img" v-bind:class="{'swap-arrow':($i18n.locale === 'ar')}" src="@/assets/arrow.png" alt="img" />

               </router-link>

            </v-col>
                  </v-row>
            </v-col>
            <v-col md="4" cols="12" class="">
                       <div v-bind:class="$i18n.locale === 'ar' ? ' upcomingAr' : 'upcoming'">{{ $t('upcoming')}}</div>
                        <div class="upcomingDiv">      <v-row class="upcoming-row" :key="newws.head" v-for="newws in upcomingnews">
              <v-col cols="5"  class="conofupcomingimg">
                       <img class="img-fluid" :src="newws.src" alt="img" />
                      <span class="upcomingDate">
                      <p class="mt-0 mb-0">  01 </p> <v-icon style="color: #F167A7;     line-height: 0;
" dark >
                  mdi-minus
                </v-icon><p class="mt-0 mb-0">  May </p>
                      </span>
            </v-col>
             <v-col cols="7" >
              <h4 v-bind:class="$i18n.locale === 'ar' ? ' upcomingHeadAr' : 'upcomingHead'">{{ $t('new.head')}}</h4>
               <p class="upcomingCaption">{{ $t('new.caption')}}</p>

            </v-col>
                  </v-row></div>

            </v-col>
        </v-row>
        
      </v-container>
    </section>

</template>
<script>
  export default {
    data () {
      return {
        items: [
          {
            src: require('@/assets/news01.jpg'),
          },
          {
            src: require('@/assets/news01.jpg'),
          },
          {
            src: require('@/assets/news01.jpg'),
          },
          {
            src: require('@/assets/news01.jpg'),
          },
        ],
         news: [
          { id:1,
            src: require('@/assets/news02.png'),
            date:'10 OCTOBER, 2021',
            head:'Quis autem vel eum iure',
            caption:'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem.'
          },
              {
                id:2,
            src: require('@/assets/news02.png'),
            date:'10 OCTOBER, 2021',
            head:'Quis autem vel eum iure',
            caption:'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem.'
          },
               {
                 id:3,
            src: require('@/assets/news02.png'),
            date:'10 OCTOBER, 2021',
            head:'Quis autem vel eum iure',
            caption:'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem.'
          },
        ],
          upcomingnews: [
          {
            src: require('@/assets/news03.png'),
            head:'Quis autem vel eum iure',
            caption:'Quis autem vel eum iure reprehenderit qui in ea'
          },
              {
            src: require('@/assets/news03.png'),
            head:'Quis autem vel eum iure',
            caption:'Quis autem vel eum iure reprehenderit qui in ea'
          },
               {
            src: require('@/assets/news03.png'),
            head:'Quis autem vel eum iure',
            caption:'Quis autem vel eum iure reprehenderit qui in ea'
            },
        ],
              slides: ["Innovation", "Design", "Technology"]

      }
    },
  }
</script>
<style lang="scss" scoped>
.cara.v-image__image.v-image__image--cover{
      background-position: top center !important;
      background-size: cover;
      height: 300px !important;

}
.swap-arrow{
  transform: rotate(180deg);
}
.sectionNews{
    padding: 2% !important;
}
.about-img{
    width: 100%;
}
.dateTxt{
      letter-spacing: 0.51px;
color: #F167A7;
text-transform: uppercase;
font-size: 17px;
}
.dayTxt{
    font-family: 'Teko-SemiBold';
    font-size: 150px;
    color: #F167A7;
}
.monthTxt{
     font-family: 'Teko-SemiBold';
    font-size: 60px;
}
.percentageTxt{
    font-family:"Philosopher-Bold";
    font-size: 60px;

    color:#A1D683;
}
.more{
      color:#A1D683;
      text-decoration: none !important;
      letter-spacing: 1px;
      font-size: 15px;
     font-family:"Philosopher-Bold";
      display: flex;
     align-items: center;
}
.moreAr{
      color:#A1D683;
      text-decoration: none !important;
      letter-spacing: 0px;
      font-size: 15px;
      display: flex;
     align-items: center;
}
.arrow-img{
  margin: 5px;
}
.conOfTxt{
    padding: 50px;
}
.img-fluid{
    width: 100%;
    height: auto;
}
.upcoming{
  background: #79257B;
  color:#fff;
    font-family:"Philosopher-Bold";
    font-size: 20px;
    padding: 15px;
}
.upcomingAr{
  background: #79257B;
  color:#fff;
    font-family:"gess-Bold";
    font-size: 20px;
    padding: 15px;
}
.upcomingDiv{
  
  padding: 20px;
  background: #FEF6FA
}
.upcomingHead{
font-family:"Philosopher-regular";
   color: #000000;
   font-size: 17px;
}
.upcomingHeadAr{
font-family:"gess-Bold";
   color: #000000;
     font-size: 15px;
}
.upcomingDate{
      position: absolute;
    background: #EDF0F2;
    right: 8%;
    font-size: 10px;
        font-family:"Philosopher-Bold";
        text-align: center;

}
.newsHead{
  font-family:"Philosopher-regular";

        font-size: 20px;
        color: #000000
}
.newsHeadAr{
   font-family:"gess-Bold";
        font-size: 20px;
        color: #000000
}
.upcomingCaption{
  font-size: 12px;
  line-height: 1.5em;
    height: 3em;
    overflow: hidden;
    
    text-overflow: ellipsis;
    width: 100%;
     display: -webkit-box;
      -webkit-box-orient: vertical;
       line-clamp: 2; 
   -webkit-line-clamp: 2;
}
.conofupcomingimg{
  position: relative;
}
</style>
